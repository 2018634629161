<template>
  <!--
    Used to list device commands, execute them and display possible results.
    commandsAvailable(maintenanceCommands) || commandsAvailable(expertCommands)
    commandsAvailable(maintenanceCommands)
  -->
  <div class="commandDialog">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template
        v-if="commandsAvailable(maintenanceCommands) || commandsAvailable(expertCommands) || commandsAvailable(peaksolutionCommands) || commandsAvailable(operatingSystemCommands) 
          || commandsAvailable(networkCommands) || commandsAvailable(applicationCommands) || commandsAvailable(printerCommands) || commandsAvailable(paymentCommands)"
      > 
        <ul
          id="myTab"
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            v-if="commandsAvailable(maintenanceCommands)"
            class="nav-item"
          >
            <a
              id="execute-tab"
              class="nav-link"
              data-toggle="tab"
              href="#execute"
              role="tab"
              aria-controls="maintenance"
              aria-selected="false"
            >{{ $t('deviceCommandDialogComp.maintenance') }}</a>
          </li>
          <li
            v-if="commandsAvailable(applicationCommands) && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="application-tab"
              class="nav-link"
              data-toggle="tab"
              href="#application"
              role="tab"
              aria-controls="application"
              aria-selected="false"
            >Application</a>
          </li>
          <li
            v-if="commandsAvailable(paymentCommands) && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="payment-tab"
              class="nav-link"
              data-toggle="tab"
              href="#payment"
              role="tab"
              aria-controls="payment"
              aria-selected="false"
            >Payment</a>
          </li>
          <li
            v-if="commandsAvailable(printerCommands) && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="printer-tab"
              class="nav-link"
              data-toggle="tab"
              href="#printer"
              role="tab"
              aria-controls="printer"
              aria-selected="false"
            >Printer</a>
          </li>
          <li
            v-if="commandsAvailable(expertCommands) && !authenticationHasRole('firstlevel') && !authenticationHasRole('firstlevel_connector') && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="expert-tab"
              class="nav-link"
              data-toggle="tab"
              href="#expert"
              role="tab"
              aria-controls="maintenance"
              aria-selected="false"
            >{{ $t('deviceCommandDialogComp.expert') }}</a>
          </li>
          <li
            v-if="commandsAvailable(peaksolutionCommands)"
            class="nav-item"
          >
            <a
              id="peaksolution-tab"
              class="nav-link"
              data-toggle="tab"
              href="#peaksolution"
              role="tab"
              aria-controls="maintenance"
              aria-selected="false"
            >Peaksolution</a>
          </li>
          <li
            v-if="commandsAvailable(operatingSystemCommands) && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="operating-system-tab"
              class="nav-link"
              data-toggle="tab"
              href="#operatingSystem"
              role="tab"
              aria-controls="operatingSystem"
              aria-selected="false"
            >OperatingSystem</a>
          </li>
          <li
            v-if="commandsAvailable(networkCommands) && !authenticationHasRole('zerolevel')"
            class="nav-item"
          >
            <a
              id="network-tab"
              class="nav-link"
              data-toggle="tab"
              href="#network"
              role="tab"
              aria-controls="network"
              aria-selected="false"
            >Network</a>
          </li>
        </ul>
        <div
          id="myTabContent"
          class="tab-content"
        >
          <div
            v-if="commandsAvailable(expertCommands)"
            id="expert"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="expert-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="expertCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(maintenanceCommands)"
            id="execute"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="execute-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="maintenanceCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(peaksolutionCommands)"
            id="peaksolution"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="peaksolution-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="peaksolutionCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(operatingSystemCommands)"
            id="operatingSystem"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="operating-system-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="operatingSystemCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(networkCommands)"
            id="network"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="network-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="networkCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(applicationCommands)"
            id="application"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="application-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="applicationCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(printerCommands)"
            id="printer"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="printer-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="printerCommands"
            />
          </div>

          <div
            v-if="commandsAvailable(paymentCommands)"
            id="payment"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="payment-tab"
          >
            <DeviceCommandDialogExecute
              :device-id="deviceId"
              :device-is-active="deviceIsActive"
              :commands="paymentCommands"
            />
          </div>
        </div>
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "CommandDialog",
  components: {
    DeviceCommandDialogExecute: () => import('@/components/Device/DeviceCommandDialogExecute.vue')
  },
  mixins: [
    authenticationMixin
  ],
  props: { 
    deviceId: {
      type: String,
      required: true
    },
    deviceIsActive: {
      type: Boolean,
      required: true
    },
    deviceCommands: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      commands: null,
      loading: false
    }
  },
  computed: {
    expertCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "expert");
    },
    maintenanceCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "maintenance");
    },
    peaksolutionCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "peaksolution");
    },
    operatingSystemCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "operatingSystem");
    },
    networkCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "network");
    },
    applicationCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "application");
    },
    printerCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "printer");
    },
    paymentCommands: function () {
      if (!this.deviceCommands) {
        return new Array();
      }
      return this.deviceCommands.filter(p => p.category == "payment");
    }
  },
  mounted () {
    if (this.deviceIsActive && this.device) {
       this.loading = false;
    } 
    if (this.commandsAvailable(this.maintenanceCommands))
    {
      document.getElementById('execute-tab').click();
    }
    else if (this.commandsAvailable(this.expertCommands))
    {
      document.getElementById('expert-tab').click();
    }
    else if (this.commandsAvailable(this.peaksolutionCommands))
    {
      document.getElementById('peaksolution-tab').click();
    }
    else if (this.commandsAvailable(this.operatingSystemCommands))
    {
      document.getElementById('operating-system-tab').click();
    }
    else if (this.commandsAvailable(this.networkCommands))
    {
      document.getElementById('network-tab').click();
    }
    else if (this.commandsAvailable(this.applicationCommands))
    {
      document.getElementById('application-tab').click();
    }
    else if (this.commandsAvailable(this.printerCommands))
    {
      document.getElementById('printer-tab').click();
    }
    else if (this.commandsAvailable(this.paymentCommands))
    {
      document.getElementById('payment-tab').click();
    }
  },
  methods: {
    commandsAvailable (commands) {
      if (commands == null) {
        return false;
      }
      if (commands.length == null) {
        return false;
      }
      if (commands.length == 0) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style>
.commandDialog {
  position: relative;
}
</style>
